<template>
    <div class="coursemenu">
        <div class="menulist" v-for="item in infolist" :key="item.id">
            <div class="menuinfo" :style="{paddingLeft:(lev*10)+'px'}" v-if="item.ismenu">
                <dl :title="$common.langinfo( item.title,item.title_en)" @click="showdetail(item.id)" :class="curid==item.id?'cs':''">
                    <dt class="overdot">{{item.x_part}} {{$common.langinfo( item.title,item.title_en)}}</dt>
                    <dd><i v-if="item.x_hassubmenu" class="iconfont icon-zhankai" :style="{transform: 'rotate('+(item.x_isopen?'180':'0')+'deg)'}"></i></dd>
                </dl>
            </div>
            <transition name="slidedown">
                <Csitcoursemenu v-show="item.x_isopen" v-if="item.x_hassubmenu" :curid="curid" :lev="item.lev" :infolist="item.x_children" @showdetail="showdetail"></Csitcoursemenu>
            </transition>
        </div>
    </div>
</template>

<script setup>

    const emit = defineEmits(['showdetail'])

    const props = defineProps({
        curid: {
            type: Number,
            default: 0
        },
        lev: {
            type: Number,
            default: 0
        },
        infolist: {
            type: Array,
            default: () => []
        }
    });

    function showdetail(id) {
        emit("showdetail", id);
    }


</script>

<style scoped>
    .coursemenu { width: 100%; flex-flow: column nowrap; padding-right: 10px; font-size: 0.9em; }
    .menulist { width: 100%; flex-flow: column nowrap; background-color: #FFF }
        .menulist .menuinfo { width: 100%; flex-flow: column nowrap; border-bottom: 1px solid #CCC; cursor: pointer; }
            .menulist .menuinfo dl { width: 100%; align-items: center; justify-content: space-between; padding: 15px 10px }
                .menulist .menuinfo dl.cs, .menulist .menuinfo dl:hover { color: #72A530 }
                .menulist .menuinfo dl dt { width: 20%; flex: auto }
                .menulist .menuinfo dl dd { width: 30px; flex: none; justify-content: flex-end }
</style>
